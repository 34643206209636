/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
import XButton from 'vux/src/components/x-button';
export default {
  name: 'BdMap',
  components: {
    XButton: XButton
  },
  data: function data() {
    return {
      mapcontrol: '',
      flag: '',
      jiaozhun: ''
    };
  },
  methods: {
    onPosition: function onPosition() {
      var _this = this;

      var that = this;
      that.$utils.Tools.loadShow('正在定位');
      that.$utils.WeiXin.wxGetLocation('allmap', this.mapcontrol, this.flag, function (data) {
        if (data.flag === false) {
          _this.$utils.Tools.toastShow(_this.$t('location.failed'));
        } else {
          _this.jiaozhun = true;

          _this.$utils.Store.setItem('address', data.address);

          _this.$utils.Store.setItem('latitude', data.latitude);

          _this.$utils.Store.setItem('longitude', data.longitude);
        }

        _this.$utils.Tools.loadHide();
      });
    },
    onDtPosition: function onDtPosition() {
      var _this2 = this;

      var that = this;
      that.$utils.Tools.loadShow('正在定位');
      that.$utils.DingTalk.dtGetLocation('allmap', this.mapcontrol, this.flag, function (data) {
        if (data.flag === false) {
          _this2.$utils.Tools.toastShow(_this2.$t('location.failed'));
        } else {
          _this2.$utils.Store.setItem('address', data.address);

          _this2.$utils.Store.setItem('latitude', data.latitude);

          _this2.$utils.Store.setItem('longitude', data.longitude);
        }

        _this2.$utils.Tools.loadHide();
      });
    },
    chongxindingwei: function chongxindingwei() {
      var _this3 = this;

      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this3.onPosition();
        } else {
          _this3.onDtPosition();
        }
      });
    }
  },
  destroy: function destroy() {
    this.$utils.Tools.loadHide();
  },
  mounted: function mounted() {
    var _this4 = this;

    this.$utils.Tools.loadShow();
    this.mapcontrol = this.$route.params.mapcontrol;
    this.flag = this.$route.params.flag; // mapcontrol = 10;
    // flag = true;

    if (+this.mapcontrol === 20) {
      this.$utils.Store.removeItem('address');
      this.$utils.Store.removeItem('latitude');
      this.$utils.Store.removeItem('longitude');
      var localUrl = window.location.href.split('#')[0];
      this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.onPosition();
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this4.onPosition();
        } else {
          _this4.onDtPosition();
        }
      });
    } else {
      var longitude = +this.$utils.Store.getItem('longitude');
      var latitude = +this.$utils.Store.getItem('latitude'); // longitude = 116.331398;
      // latitude = 39.897445;

      this.$utils.Position.getMarkerBd('allmap', longitude, latitude, this.mapcontrol, this.flag, function (data) {
        if (data.flag === false) {
          _this4.$utils.Tools.toastShow(_this4.$t('location.failed'));
        } else {
          _this4.$utils.Store.setItem('address', data.address);

          _this4.$utils.Store.setItem('latitude', data.latitude);

          _this4.$utils.Store.setItem('longitude', data.longitude);
        }

        _this4.$utils.Tools.loadHide();
      });
    }
  }
};